import React, {useState, useRef, useEffect} from 'react'

import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useUTMContext} from '@lib/gtm'
import {t} from '@lib/helpers'
import {useAsync} from '@lib/hooks'
import {sendEmail} from '@lib/services'
import {acceptConsent} from '@lib/utils/consent'

import Checkbox from './Checkbox'
import {FormInput, FormTextArea} from './custom'

const schema = yup.object().shape({
  name: yup.string().required('First Name is required'),
  surname: yup.string().required('Surname is required'),
  company: yup.string().required('Company Name is required'),
  position: yup.string().optional(),
  phone: yup
    .string()
    .matches(/^\d{10}$/, 'Phone Number must be exactly 10 digits')
    .required('Phone Number is required'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Email must be in the format user@domain.com'
    )
    .required('Email is required'),
  message: yup.string().required('Message is required'),
})

export const Form = ({title}) => {
  const {run: runSendEmail, isLoading, isSuccess} = useAsync(sendEmail)
  const {data: utm} = useUTMContext()
  const [isComplete, setIsComplete] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors},
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      isAcceptNews: true,
      isAccept: false,
    },
  })
  const watchFields = watch(['isAccept', 'isAcceptNews'])
  const ref = useRef()

  const onSubmit = async (data) => {
    const fullName = `${data.name?.trim()} ${data.surname.trim()}`

    const emailData = {
      to: process.env.SALES_EMAIL,
      subject: `[${window.location.host}] ${data.email} wants to contact with you`,
      message: `
        Name: ${fullName}
        Email: ${data.email}
        Tel: (+66) ${data.phone}
        Company: ${data.company}
        Position: ${data.position}
        Description : ${data.message}
      `,
    }

    const reqdata = {
      name: fullName,
      company: data.company,
      position: data.position,
      email: data.email,
      phone: data.phone,
      message: data.message,
      template: 'consult',
      url: window.location.href,
    }

    const res = await runSendEmail({
      emailData: {...emailData, path: process.env.EMAIL_API_URL},
      reqBody: reqdata,
      utm: utm,
    })

    runSendEmail({
      emailData: {
        path: process.env.SLACK_API_URL,
        text: `${emailData.subject}\n${emailData.message}`,
        webhook_url: process.env.SLACK_WEBHOOK_URL,
      },
      reqBody: reqdata,
      utm,
    })

    if (res.status === 200 && data.isAcceptNews) {
      await acceptConsent({
        apiKey: process.env.CONSENT_WOW_CONTACT_API_KEY,
        purposeId: process.env.CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID,
        data: {
          ...data,
          name: data.name,
          surname: data.surname,
          phone_number: data.phone_number,
        },
      })
    }
  }

  useEffect(() => {
    reset()
    setIsComplete(isSuccess)
  }, [isSuccess])

  useEffect(() => {
    if (isComplete) {
      setTimeout(() => {
        setIsComplete(false)
      }, 5000)
    }
  }, [isComplete])

  return (
    <div className='form'>
      <div className='title' dangerouslySetInnerHTML={{__html: title}} />
      <div
        className='title-mobile'
        dangerouslySetInnerHTML={{
          __html: t('layout.Footer.form.title_mobile'),
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)} id='data_labeling_form' ref={ref}>
        <div className='fields'>
          <FormInput
            name='name'
            label='First Name'
            required={true}
            placeholder='First Name'
            register={register}
            error={errors?.name?.message}
          />
          <FormInput
            name='surname'
            label='Surname'
            required={true}
            placeholder='Surname'
            register={register}
            error={errors?.surname?.message}
          />
          <FormInput
            name='company'
            label='Company'
            required={true}
            placeholder='Company Name'
            register={register}
            error={errors?.company?.message}
          />
          <FormInput
            name='position'
            label='Position (Optional)'
            placeholder='Position (Optional)'
            register={register}
            error={errors?.position?.message}
          />
          <FormInput
            name='phone'
            label='Phone Number'
            required={true}
            placeholder='Phone Number'
            register={register}
            error={errors?.phone?.message}
          />
          <FormInput
            name='email'
            type='email'
            required={true}
            label='Email'
            placeholder='Email'
            register={register}
            error={errors?.email?.message}
          />
        </div>
        <FormTextArea
          name='message'
          required={true}
          label='Message'
          placeholder='Message'
          register={register}
        />
        <div className='condition'>
          <Checkbox
            className='checkbox'
            isChecked={getValues('isAccept')}
            onClick={() => {
              setValue('isAccept', !getValues('isAccept'))
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t('layout.Footer.form.privacy_policy'),
              }}
              className='label'
            />
          </Checkbox>
          <Checkbox
            name='accept'
            className='checkbox'
            isChecked={
              getValues('isAcceptNews') === undefined
                ? true
                : getValues('isAcceptNews')
            }
            onClick={() => {
              setValue('isAcceptNews', !getValues('isAcceptNews'))
            }}
          >
            {t('layout.Footer.form.marketing_consent')} &#8203;
            <span
              style={{
                whiteSpace: 'nowrap',
                color: 'inherit',
                fontWeight: 'inherit',
              }}
            >
              {t('layout.Footer.form.unsubscribe')}
            </span>
          </Checkbox>
        </div>
        <button
          type='submit'
          disabled={isLoading || !watchFields[0]}
          className='form-button'
        >
          {isComplete
            ? t('layout.GetStarted.form.sent')
            : isLoading
            ? t('layout.loading')
            : t('layout.Footer.form.submit')}
        </button>
      </form>
    </div>
  )
}
