import axios from 'axios'

import {submitForm} from './submit-form'

export const sendEmail = async ({emailData, reqBody, utm}) => {
  const [{status, data}] = await Promise.all([
    await axios.post('/api/send-noti', emailData),
    await submitForm({reqBody, utm}),
  ])
  return {status, data}
}
